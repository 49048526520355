@import "node_modules/pe7-icon/dist/scss/pe-icon-7-stroke";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$esource-web-primary: mat-palette($mat-indigo);
$esource-web-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$esource-web-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$esource-web-theme: mat-light-theme((
  color: (
    primary: $esource-web-primary,
    accent: $esource-web-accent,
    warn: $esource-web-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($esource-web-theme);



html {
  box-sizing: border-box;
  scroll-behavior: smooth;  
}

*,
*:before,
*:after {
	box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.img-size{
  max-width: 100%;
max-height: 30rem;
min-width: 100%;
}

body {
	background: #fff;
	// color: #333;
    font-family: 'Poppins', helvetica, arial, sans-serif;
    font-size: 0.9575rem;
    font-weight: 400;
    // line-height: 1.66;
    margin: 0;
}

p{
  font-family: 'Poppins', helvetica, arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', helvetica, arial, sans-serif;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    letter-spacing: -1px;
    color: #131529;
}

dl, ol, ul{
  margin-bottom: 0;
}


/*     Paises       */

svg {
  display: block;
  width: 100%;
}
#states *.on {
  fill: red;
}

.list-of-states {
  margin: 0;
  padding: 0;
  columns: 100px auto;
  column-gap: 8px;
  list-style: none;
}
.list-of-states li {
  padding: 2px 4px;
  display: inline-block;
  width: 100%;
}
.list-of-states li.on {
  background: red;
  color: white;
  font-weight: bold;
}


/*    Menu responsive    */

.wsmenu-click{
  width: 50px;
  height: 100%;
  position: absolute;
  cursor: pointer;
  right: 0;
}

.icon{
  right: 11px;
  width: 12px;
  height: 12px;
  background: url('assets/img/arrow-down-white.svg') no-repeat;
  margin: 0 0 0 9px;
  position: absolute;
  text-align: right;
  top: 19px;
}

 @media only screen and (max-width:780px) {
  .xmenu {
    display: none;
  }
}

.searchbar{
  border-left: solid;
    border-left-width: 10px;
    border-left-color: #38b7ea;
    border-radius: 15px;
}


html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

